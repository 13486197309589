import React, {
  memo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import getConfig from 'next/config';
// must use compose from recompose not react-apollo, because of use of withHandlers and withProps
import { compose, withHandlers } from 'recompose';

import { GoogleMap } from '@react-google-maps/api';

import { Icon } from '../ui';
import MapFilterControls from './MapFilterControls';
import ViewManager from '../../managers/view';

import {
  Footer,
  ZoomAction,
  ZoomControls,
} from './Map.style';

import {
  GOOGLE_API_MAP_ZOOM_DEFAULT,
} from '../../config/apis';

import mapStyleConfig from './map.style.config';

import svgAdd from '../../static/images/icons/baseline-add-24px.svg';
import svgRemove from '../../static/images/icons/baseline-remove-24px.svg';
import theme from '../../styles/config/theme';
import mapResponsiveControls from './mapResponsiveControls';
import { startTiming, types } from '../../lib/timings';

const { ENVIRONMENT } = getConfig().publicRuntimeConfig;

const MIN_ZOOM = 6;
const MAX_ZOOM = 22;

const STICKY_MODE = {
  none: 'None',
  sticky: 'Sticky',
  bottom: 'Bottom',
};

const MAP_CONTAINER_STYLE = { height: '100%', width: '100%' };

const MAP_OPTIONS = {
  disableDefaultUI: true,
  // Only enable scroll to zoom on production builds
  gestureHandling: process.env.NODE_ENV === 'production' ? 'greedy' : '',
  minZoom: MIN_ZOOM,
  maxZoom: MAX_ZOOM,
  // If we have a map ID, don't include styles, as if we have an ID
  // the styles come from the cloud console
  ...(ENVIRONMENT.GOOGLE_MAP_ID ? ({
    mapId: ENVIRONMENT.GOOGLE_MAP_ID,
  }) : ({
    styles: mapStyleConfig,
  })),
};

const MAP_STYLE = { height: '100%', width: '100%', position: 'absolute' };

const GoogleMapComponent = compose(
  withHandlers(() => {
    // Assign map ref to be used in handlers
    let map;

    return {
      // Event handler for zoom controls
      handleZoom: ({
        defaultZoom, onZoomChanged, zoom,
      }) => (n) => {
        startTiming(types.MAP_ZOOM);
        const newZoomLevel = (zoom || defaultZoom || map.getZoom()) + n;
        const minZoom = MIN_ZOOM || null;
        const zoomLevel = Math.max(newZoomLevel, minZoom);
        onZoomChanged(zoomLevel);
      },
      // Assign ref and lift to parent
      onMapMounted: ({ onMounted }) => (ref) => {
        if (ref) {
          map = ref;
        }
        onMounted(map);
      },
      // Event handler for map zoom level change
      onZoomChanged: ({ onZoomChanged }) => () => (
        map && onZoomChanged(map.getZoom())
      ),
    };
  }),
  memo,
)(({ children, ...props }) => {
  const [isStickyMode, setIsStickyMode] = useState(STICKY_MODE.none);
  const footerRef = useRef();
  const mapElement = props.searchWrapperRef?.current;
  const mapDimensions = mapElement?.getBoundingClientRect();
  const mapWidth = mapDimensions?.width;

  const onUpdate = useCallback(({ scrollUpdate, resizeUpdate }) => {
    // JS handling of sticky to cover non height of footer
    const footer = footerRef.current;
    const map = props.searchWrapperRef?.current;

    if ((scrollUpdate || resizeUpdate) && footer && map) {
      const mapBounds = map.getBoundingClientRect();
      mapResponsiveControls(mapBounds.width);
      let stickyMode = STICKY_MODE.none;

      if (mapBounds.bottom > mapBounds.height && ViewManager.isAbove(theme.MOBILE_LARGE)) {
        stickyMode = STICKY_MODE.sticky;
      }

      let hasTransitionedToHandheld = false;
      if (resizeUpdate && ViewManager.hasTransitionedBelow(theme.TABLET)) {
        hasTransitionedToHandheld = true;
      }

      if (
        stickyMode !== isStickyMode
        || (stickyMode && resizeUpdate)
        || hasTransitionedToHandheld
      ) {
        // directly set for performance, use of state is too slow
        if (STICKY_MODE.sticky === stickyMode) {
          footer.style.position = 'fixed';
          footer.style.width = `${mapBounds.width}px`;
        } else {
          footer.style.position = '';
          footer.style.width = '';
        }

        setIsStickyMode(stickyMode);
      }
    }
  }, [isStickyMode]);

  useEffect(() => {
    ViewManager.invalidate();
  }, []);

  useEffect(() => {
    ViewManager.on(ViewManager.EVENT_UPDATE, onUpdate);
    return () => {
      ViewManager.off(ViewManager.EVENT_UPDATE, onUpdate);
    };
  }, [props.searchWrapperRef?.current, isStickyMode]);

  // Ensures if the map width changes, we resize the footer to
  // fit to the new width
  useEffect(() => {
    onUpdate({ resizeUpdate: true });
  }, [mapWidth]);

  const isTours = props.query.isTours === 'true' && props.query.isOverseas === 'true';

  const isSitecard2Visible = !!props.query.siteCode;
  const isSitecard3Visible = !!props.query.campsiteId;

  return (
    <>
      <ZoomControls>
        <ZoomAction
          aria-label="Zoom in"
          disabled={props.zoom >= MAX_ZOOM}
          onClick={() => props.handleZoom(1)}
          role="button"
        >
          <Icon icon={svgAdd} />
        </ZoomAction>
        <ZoomAction
          aria-label="Zoom out"
          disabled={props.zoom <= MIN_ZOOM}
          onClick={() => props.handleZoom(-1)}
          role="button"
        >
          <Icon icon={svgRemove} />
        </ZoomAction>
      </ZoomControls>
      <Footer ref={footerRef} activePin={props.query.activePin}>
        {(props.campsiteTypes && props.onFiltersChange && !props.query.ukItinerary) &&
          <MapFilterControls
            onFiltersChange={props.onFiltersChange}
            selectedTypes={props.types}
            campsiteTypes={props.campsiteTypes}
            isTours={isTours}
            handleClickMyLocation={props.handleClickMyLocation}
            isLocationActive={props.isLocationActive}
            userLocationIsVisible={props.userLocationIsVisible}
            onLocationButtonClick={props.onLocationButtonClick}
            locationPermission={props.locationPermission}
            isOverseas={props.query.isOverseas === 'true'}
            hideMyLocationButton={isSitecard2Visible || isSitecard3Visible}
          />
        }
      </Footer>

      <div data-component-element="true" style={MAP_STYLE}>
        <GoogleMap
          center={props.defaultCenter}
          options={MAP_OPTIONS}
          mapContainerClassName="Map"
          mapContainerStyle={MAP_CONTAINER_STYLE}
          onClick={props.onClick}
          onDragEnd={props.onDragEnd}
          onIdle={props.onIdle}
          onTilesLoaded={props.onTilesLoaded}
          onZoomChanged={props.onZoomChanged}
          onLoad={props.onMapMounted}
          zoom={props.zoom || props.defaultZoom || GOOGLE_API_MAP_ZOOM_DEFAULT}
          clickableIcons={false}
        >
          {children}
        </GoogleMap>
      </div>
    </>
  );
});

export default GoogleMapComponent;
